.max-ad__wrap .clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0; }

.max-ad__wrap h4 {
  font-size: 18px; }

.max-ad__wrap h5 {
  font-size: 16px; }
